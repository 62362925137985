<template>
  <div>
    <normalPart :cont="cont"></normalPart>
  </div>
</template>

<script>
// @ is an alias to /src
import NormalPart from '../components/normalPart'
export default {
  name: 'FirstPart',
  components: { NormalPart },
  data() {
    return {
      cont: {
        title: '网培政策规范评测',
        desc:
          '   本评测旨在考核被评测是否掌握从事网络培训工作需要具备的相关政策法规知识。同时，通过评测促使被测评者重视政策法规在网络培训中的重要意义，提升自身教学行为的合法合规性，进而带动整个在线教育培训行业领域的政策规范水平。',
        target:
          ' 本评测主要适用对象是从事网络培训领域工作，并致力于在网络培训领域获得进一步发展的人员，帮助他们获得相关领域能力的标准评定，同时也适用于教育培训类企业对相关岗位员工的招聘与管理，做为评价的依据。<br/>    评测内容主要围绕《中华人民共和国保守国家秘密法》、《著作权法》等国家正式发布的法律法规，以及各平台行业组织发布的网络培训相关文明礼仪要求等来进行评测。',
        methods: ` 本项评测要求参与者采用在线答题的方式进行，共100道选择题，用时约90分钟，正确率超过60%即视为通过。被评测者在参加评测期间应该遵守考试纪律，独立完成评测内容。考试时不得向他人求助，也不能通过搜索引擎、书本等工具查阅题目相关内容。`,
        symble: [
          `
          《中华人民共和国保守国家秘密法》<br />
1.中华人民共和国保守国家秘密法主要内容<br />
2.相关案例<br /><br />
        
         `,
          ` <br />《中华人民共和国著作权法》<br />
1.中华人民共和国著作权法主要内容<br />
2.相关案例`,
          `  <br />《中华人民共和国计算机信息系统安全保护条例》<br />
1.中华人民共和国计算机信息系统安全保护条例主要内容<br />
2.相关案例`,
          ` <br />《计算机软件保护条例》<br />
1.计算机软件保护条例主要内容<br />
2.相关案例`,
          `<br />《互联网电子公告服务管理规定》<br /> 1、互联网电子公告服务管理规定主要内容<br />
2.相关案例`,
          `<br />《信息网络传播权保护条例》<br />
1.信息网络传播权保护条例主要内容<br />
2.相关案例`,
          `
<br />《中华人民共和国网络安全法》<br />
1.中华人民共和国网络安全法主要内容<br />
2.相关案例`,
          `<br />《互联网直播服务管理规定》<br />
1.互联网直播服务管理规定主要内容 <br />
2.相关案例`,
          `<br />以及国家颁布的其他有关的计算机及互联网教育方面的政策及法律、法规。<br />`,
        ],
      },
    }
  },
}
</script>
<style lang="less" scoped></style>
