<template>
  <div class="first-part">
    <div class="wrapper">
      <!-- <div class="menu">
        <ul>
          <li
            class="menu-item flex-row-between"
            v-for="(item, index) in menu"
            :class="{'active': getCurrentIndex() == index}"
            :key="index"
            @click="handleClick(index)"
          >
            <div class="menu-item-content" v-text="item.label"></div>
            <div>
              <strong>></strong>
            </div>
          </li>
        </ul>
      </div> -->
      <div class="content" ref="content">
        <div>
          <!-- <el-button type="danger" class="exam" @click="exam(type)"
            >进入考试系统</el-button
          > -->
          <h2 class="part-title text-center" v-text="cont.title"></h2>
          <div class="content-item" ref="rItem">
            <h3 class="little-title">测评目的</h3>
            <p class="part-cont" v-html="cont.target"></p>
          </div>
          <div class="content-item" ref="rItem">
            <h3 class="little-title">评测介绍</h3>
            <p class="part-cont" v-html="cont.desc"></p>
          </div>
          <div class="content-item" ref="rItem">
            <h3 class="little-title">测评方式</h3>
            <p class="part-cont" v-html="cont.methods">
              本项评测要求参与者采用在线答题的方式进行，共100道选择题，用时约45分钟，正确率超过80%即视为通过。
              <br />评测期间被评测者在参加评测期间应该遵守考试纪律，独立完成评测内容，考试期间不能通过搜索引擎、书本等工具查阅题目相关内容。
              <br />本评测需要上传一段个人数字化能力教学视频，用于评测个人具体实操能力。
            </p>
          </div>
          <div class="content-item" ref="rItem">
            <h3 class="little-title">测评大纲</h3>
            <p
              class="part-cont"
              v-for="item in cont.symble"
              :key="item"
              v-html="item"
            ></p>
          </div>
          <div class="content-item" ref="rItem">
            <h3 class="little-title">测评费用</h3>
            <p class="part-cont">
              网培师的评测费用为{{
                this.$price
              }}/人，含三个模块的评测。（费用包含模拟考试练习，每个评测模块各1次正式考试及1次补考，如果第一次考试没有通过，可自动获得1次补考机会，如补考再未能通过，需要重新缴费后参加新一次测评）。
            </p>
          </div>
          <div class="content-item" ref="rItem">
            <h3 class="little-title">测评证书</h3>
            <p class="part-cont">
              评测通过后，平台将综合考生的成绩，颁发相应证书。并同时由教育区块链学习银行颁发数字证书。
            </p>
          </div>
          <!-- <el-button type="danger" class="exam" @click="exam(type)">进入考试系统</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import BScroll from "better-scroll";
export default {
  name: 'Part',
  props: ['cont'],
  data() {
    return {
      type: 0,
      contentScroll: null,
      listHeight: [], // 用来存储每一个子盒子的高度
      currentIndex: 0,
      scrollY: 0,
      menu: [
        {
          label: '评测目的',
          id: 0,
        },
        {
          label: '评测介绍',
          id: 1,
        },
        {
          label: '评测方式',
          id: 2,
        },
        {
          label: '评测大纲',
          id: 3,
        },
        {
          label: '评测费用',
          id: 4,
        },
        {
          label: '评测证书',
          id: 5,
        },
      ],
    }
  },
  created() {
    switch (this.$route.path) {
      case '/firstpart':
        this.type = '1'
        break
      case '/secondpart':
        this.type = '2'
        break
      default:
        this.type = '3'
        break
    }
  },
  methods: {
    exam(type) {
      if (this.$store.state.wps_user.mobile) {
        this.$router.push({ name: 'Exam', params: { id: type } })
      } else {
        this.$message.warning('您还未登录，请先登录！')
      }
    },
    getCurrentIndex() {
      const index = this.listHeight.findIndex((item, index) => {
        return (
          this.scrollY >= this.listHeight[index] &&
          this.scrollY < this.listHeight[index + 1]
        )
      })

      return index > 0 ? index : 0
    },
    handleClick(index) {
      this.scrollY = this.listHeight[index]

      const content = this.$refs.content
      const contentList = content.getElementsByClassName('content-item')
      const el = contentList[index]
      // // scrollToElement：滚动到目标元素
      // this.contentScroll.scrollToElement(el, 300);
      let windowH = window.innerHeight //浏览器窗口高度
      let h = el.offsetHeight //模块内容高度
      let t = el.offsetTop //模块相对于内容顶部的距离
      let top = t - (windowH - h) / 2 + 200 //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop //滚动条距离顶部高度
      let currentTop = scrollTop //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向下滚动
          currentTop += 30
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - 30)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向上滚动
          currentTop -= 30
        }
      }
      window.requestAnimationFrame(step)
    },
    getHeightList() {
      this.listHeight = []
      let height = 0
      this.listHeight.push(height)
      const content = this.$refs.content
      const contentList = content.getElementsByClassName('content-item')
      for (let i = 0; i < contentList.length; i++) {
        const item = contentList[i]
        height += item.clientHeight
        this.listHeight.push(height)
      }
    },
  },
  mounted() {
    this.$nextTick(function() {
      // 由于图片太大，加载比较慢，造成还没有完全加载完就初始化了，导致滚动不了，所以这里加个定时器延迟下
      setTimeout(() => {
        this.getHeightList()
      }, 400)
    })
  },
}
</script>
<style lang="less" scoped>
.first-part {
  font-weight: normal;
  font-size: 16px;
  width: 60%;
  min-width: 1100px;
  margin: 40px auto 0;
  position: relative;
  color: #656565;
  img {
    width: 100%;
  }

  .wrapper {
    display: flex;
    width: 100%;
    // overflow: hidden;
    padding-bottom: 30px;
  }
  .menu {
    width: 256px;
    height: 380px;
    margin-right: 24px;
    border: #e3e3e3 2px solid;
    border-radius: 6px;
    position: fixed;
    background: #fff;
    z-index: 999;
  }
  .content {
    flex: 1;
    // overflow: hidden;
    position: relative;
    // height: 1600px;
    // padding-left: 280px;
    // max-height: 550px;
    // overflow: auto;
  }
  .content::-webkit-scrollbar {
    display: none;
  }
  .menu-item {
    padding: 20px 30px;
    color: #000005;
    border-radius: 6px;
    cursor: pointer;
  }
  .menu-item-content {
    width: 100%;
    font-size: 18px;
  }
  .part-title {
    font-size: 30px;
    padding-bottom: 40px;
    color: #000005;
  }
  .part-cont {
    line-height: 30px;
    color: #333;
  }
  .little-title {
    font-size: 22px;
    color: #000005;
    padding-bottom: 8px;
  }
  .active {
    // border-color: #cd996b;
    border-color: #960f23;
    // background: #cd996b;
    background: #960f23;
    color: #fff;
  }
  .content-item {
    margin-bottom: 20px;
  }
  .exam {
    background: #e10133;
    width: 180px;
    color: #fff;
    margin-left: 900px;
    margin-bottom: 20px;
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
